import LocalizedStrings from "react-localization";
import langNL from "./language_NL";
import langBE from "./language_BE";
import langEN from "./language_EN";
import langFR from "./language_FR";
import langDE from "./language_DE";
import langES from "./language_ES";
import langPL from "./language_PL";

export const languageNames = {
  nl: "Nederlands",
  en: "English",
  de: "Deutsch",
  fr: "Français",
  es: "Español",
  pl: "Polski",
};

let nl = langNL;
let domain = ((window.location.hostname || "").split(".") || [])[
  ((window.location.hostname || "").split(".") || []).length - 1
];
if (domain === "be") {
  nl = { ...langNL, ...langBE };
}
const en = langEN;
const de = langDE;
const fr = langFR;
const es = langES;
const pl = langPL;

const strings = new LocalizedStrings({ en, nl, de, fr, es, pl });

// let navigatorLanguage =
//   navigator.userLanguage ||
//   navigator.language ||
//   navigator.browserLanguage ||
//   navigator.systemLanguage;

// if (navigatorLanguage) {
//   navigatorLanguage = navigatorLanguage.split(/[_-]/)[0].toLowerCase();
// }

// if (navigatorLanguage && languageNames[navigatorLanguage]) {
//   strings.setLanguage(navigatorLanguage);
// } else {
//   if (domain === "nl" || domain === "be") {
//     strings.setLanguage("nl");
//   } else if (domain === "de") {
//     strings.setLanguage("de");
//   } else if (domain === "fr") {
//     strings.setLanguage("fr");
//   } else if (domain === "es") {
//     strings.setLanguage("es");
//   } else {
//     strings.setLanguage("en");
//   }
// }

// if ((window.location.hostname || "").includes("weplayinside")) {
//   strings.setLanguage("nl");
// }

// if (localStorage.getItem("userLanguage")) {
//   strings.setLanguage(localStorage.getItem("userLanguage"));
// }

export const languages = strings;
