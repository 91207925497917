import "./assets/css/animations.css";
import "./assets/css/material-kit.css";
import React, { Component } from "react";
import { Routes } from "./components/Routes";

// load custom css last
import "./assets/css/custom.css";
import { languages } from "./constants/languages";

class App extends Component {
  state = {
    user: {},
    userLoaded: false,

    buildVersionNr3: 20, // persistent snackbar with refresh button
    currentVersionNr3: null,
    showUpdateSnackbar: false,
  };

  componentDidMount = () => {
    window.firebase.auth().onAuthStateChanged(
      function (user) {
        if (user) {
          this.setState({ user: user, userLoaded: true });
        } else {
          window.firebase.auth().signInAnonymously();
        }
      }.bind(this)
    );

    this.unsubscribe = window.firebase
      .firestore()
      .collection("version")
      .doc("version")
      .onSnapshot((doc) => {
        let versionData = doc.data();

        let showUpdateSnackbar = false;
        if (versionData.nr3 > this.state.buildVersionNr3) {
          showUpdateSnackbar = true;
        }

        this.setState({
          currentVersionNr3: versionData.nr3,
          showUpdateSnackbar: showUpdateSnackbar,
        });
      });
  };

  render() {
    return (
      <div>
        {this.state.showUpdateSnackbar && (
          <div className="alert alert-tournify" role="alert">
            <div className="alert-tournify-text">
              {languages.versionUpdateMessage}
            </div>
            <button
              type="button"
              className="btn btn-tournify"
              style={{ padding: "10px 15px" }}
              onClick={() => {
                window.location.reload();
              }}
            >
              {languages.refreshPage}
            </button>
          </div>
        )}
        <Routes user={this.state.user} userLoaded={this.state.userLoaded} />
      </div>
    );
  }
}

export default App;
