import moment from "moment-timezone";
import React, { Component, Suspense } from "react";
import { Error404 } from "../../components/404";
import { Loading } from "../../components/Loading";
import { addUserColorStylesheet } from "./addUserColorStylesheet";
import { languages, languageNames } from "../../constants/languages";

const DataContainer = React.lazy(() => import("./DataContainer"));

class TournamentContainer extends Component {
  state = {
    notFound: false,
    tournament: undefined,
    manifestLoaded: false,
  };

  loadManifest = (title) => {
    var myDynamicManifest = {
      name: title,
      short_name: title,
      display: "fullscreen",
      theme_color: "#000000",
      background_color: "#ffffff",
      icons: [
        {
          src: "favicon.ico",
          type: "image/x-icon",
          sizes: "64x64 32x32 24x24 16x16",
        },
      ],
      start_url: "./" + this.props.liveLink.toLowerCase(),
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#manifest-placeholder")
      .setAttribute("href", manifestURL);
    this.setState({ manifestLoaded: true });
  };

  componentDidMount = () => {
    this.unsubscribeTournament = window.firebase
      .firestore()
      .collection("tournaments")
      .where(
        "liveLink",
        "==",
        this.props.liveLink ? this.props.liveLink.toLowerCase() : null
      )
      .onSnapshot((querySnaphot) => {
        if (!querySnaphot.empty) {
          querySnaphot.forEach((doc) => {
            let tournament = doc.data();
            tournament[".key"] = doc.id;

            // start set language
            let tournamentLanguages = tournament.lang || [];
            let userLanguage = null;
            if (localStorage) {
              userLanguage = localStorage.getItem("userLanguage");
            }
            // console.log("tournamentLanguages", tournamentLanguages);
            // console.log("userLanguage", userLanguage);
            if ((window.location.hostname || "").includes("weplayinside")) {
              languages.setLanguage("nl");
            } else if (
              userLanguage &&
              (tournamentLanguages.length === 0 ||
                tournamentLanguages.indexOf(userLanguage) !== -1)
            ) {
              // console.log("setting user language", userLanguage);
              languages.setLanguage(userLanguage);
            } else if (tournamentLanguages.length === 1) {
              // console.log("setting single language", tournamentLanguages[0]);
              languages.setLanguage(tournamentLanguages[0]);
            } else {
              let domain = ((window.location.hostname || "").split(".") || [])[
                ((window.location.hostname || "").split(".") || []).length - 1
              ];
              let navigatorLanguage =
                navigator.userLanguage ||
                navigator.language ||
                navigator.browserLanguage ||
                navigator.systemLanguage;

              if (navigatorLanguage) {
                navigatorLanguage = navigatorLanguage
                  .split(/[_-]/)[0]
                  .toLowerCase();
              }
              // console.log("navigator language", navigatorLanguage);
              if (
                navigatorLanguage &&
                languageNames[navigatorLanguage] &&
                (tournamentLanguages.length === 0 ||
                  tournamentLanguages.indexOf(navigatorLanguage) !== -1)
              ) {
                // console.log("setting navigator language", navigatorLanguage);
                languages.setLanguage(navigatorLanguage);
              } else if (tournamentLanguages.length === 0) {
                // console.log("setting domain language", domain);
                if (domain === "nl" || domain === "be") {
                  languages.setLanguage("nl");
                } else if (domain === "de") {
                  languages.setLanguage("de");
                } else if (domain === "fr") {
                  languages.setLanguage("fr");
                } else if (domain === "es") {
                  languages.setLanguage("es");
                } else {
                  // console.log("setting default domain language en");
                  languages.setLanguage("en");
                }
              } else {
                // console.log(
                //   "setting first tournament language as default",
                //   tournamentLanguages[0]
                // );
                languages.setLanguage(tournamentLanguages[0]);
              }
            }

            if (languages.getLanguage() === "nl") {
              require("moment/locale/nl");
            }

            if (languages.getLanguage() === "es") {
              require("moment/locale/es");
            }

            if (languages.getLanguage() === "fr") {
              require("moment/locale/fr");
            }

            if (languages.getLanguage() === "de") {
              require("moment/locale/de");
            }

            if (languages.getLanguage() === "pl") {
              require("moment/locale/pl");
            }
            // end set language

            this.setState({ notFound: false, tournament: tournament });
            document.title = tournament.name;
            if (!this.state.manifestLoaded) {
              this.loadManifest(tournament.name);
            }
          });
        } else {
          this.setState({ notFound: true, tournament: undefined });
        }
      });
  };

  componentWillUnmount = () => {
    this.unsubscribeTournament();
  };

  render() {
    let toRender = <Loading />;
    const tournament = this.state.tournament;

    if (tournament && this.props.userLoaded) {
      addUserColorStylesheet(
        tournament.color || "#2278cf",
        tournament.isPSD ? "black" : ""
      );
      moment.tz.setDefault(tournament.timezone || "Europe/Amsterdam");

      toRender = (
        <Suspense fallback={<Loading />}>
          <DataContainer {...this.props} tournament={tournament} />
        </Suspense>
      );
    } else if (this.state.notFound) {
      toRender = <Error404 />;
    }
    return toRender;
  }
}

export default TournamentContainer;
