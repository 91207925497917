import React from "react";
import tournifyLoader from "../assets/img/tournifyLoader.png";

export const Loading = () => (
  <img
    alt=""
    src={tournifyLoader}
    style={{
      top: "50%",
      left: "50%",
      width: 100,
      height: 100,
      marginTop: 0,
      marginLeft: 0,
      position: "absolute",
      transform: "translate(-50%,-50%)",
      animation: "animate 1.5s linear infinite",
    }}
  />
);
