import React from "react";
import { Switch, Route } from "react-router-dom";
import TournamentContainer from "../containers/tournament/TournamentContainer";

export const Routes = (props) => (
  <Switch>
    <Route
      path="/:liveLink/team"
      exact
      render={(path) => (
        <TournamentContainer
          teamPage={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/team/:teamId"
      exact
      render={(path) => (
        <TournamentContainer
          teamPage={true}
          user={props.user}
          userLoaded={props.userLoaded}
          teamId={path.match.params.teamId}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/team/:teamId/openapp/:id"
      exact
      render={(path) => (
        <TournamentContainer
          teamPage={true}
          openApp={path.match.params.id ? true : false}
          user={props.user}
          userLoaded={props.userLoaded}
          teamId={path.match.params.teamId}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/team/:teamId/match/:matchId"
      exact
      render={(path) => (
        <TournamentContainer
          teamPage={true}
          user={props.user}
          userLoaded={props.userLoaded}
          teamId={path.match.params.teamId}
          liveLink={path.match.params.liveLink}
          matchPage={true}
          matchPageId={path.match.params.matchId}
        />
      )}
    />
    <Route
      path="/:liveLink/team/:teamId/:shareCode"
      exact
      render={(path) => (
        <TournamentContainer
          teamPage={true}
          user={props.user}
          userLoaded={props.userLoaded}
          teamId={path.match.params.teamId}
          liveLink={path.match.params.liveLink}
          shareCode={path.match.params.shareCode}
        />
      )}
    />
    <Route
      path="/:liveLink/signup/:signupId"
      exact
      render={(path) => (
        <TournamentContainer
          signup={true}
          user={props.user}
          userLoaded={props.userLoaded}
          signupId={path.match.params.signupId}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink"
      exact
      render={(path) => (
        <TournamentContainer
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/standings"
      exact
      render={(path) => (
        <TournamentContainer
          standings={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/standings/match/:matchId"
      exact
      render={(path) => (
        <TournamentContainer
          standings={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          matchPage={true}
          matchPageId={path.match.params.matchId}
        />
      )}
    />
    <Route
      path="/:liveLink/schedule"
      exact
      render={(path) => (
        <TournamentContainer
          schedule={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/schedule/match/:matchId"
      exact
      render={(path) => (
        <TournamentContainer
          schedule={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          matchPage={true}
          matchPageId={path.match.params.matchId}
        />
      )}
    />
    <Route
      path="/:liveLink/referee"
      exact
      render={(path) => (
        <TournamentContainer
          refereesPage={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/referee/:refereeId"
      exact
      render={(path) => (
        <TournamentContainer
          user={props.user}
          refereesPage={true}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          refereeId={path.match.params.refereeId}
        />
      )}
    />
    <Route
      path="/:liveLink/referee/:refereeId/match/:matchId"
      exact
      render={(path) => (
        <TournamentContainer
          user={props.user}
          refereesPage={true}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          refereeId={path.match.params.refereeId}
          matchPage={true}
          matchPageId={path.match.params.matchId}
        />
      )}
    />
    <Route
      path="/:liveLink/referee/:refereeId/:shareCode"
      exact
      render={(path) => (
        <TournamentContainer
          user={props.user}
          refereesPage={true}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          shareCode={path.match.params.shareCode}
          refereeId={path.match.params.refereeId}
        />
      )}
    />
    <Route
      path="/:liveLink/admin/:shareCode"
      exact
      render={(path) => (
        <TournamentContainer
          user={props.user}
          adminPage={true}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          shareCode={path.match.params.shareCode}
        />
      )}
    />
    <Route
      path="/:liveLink/signup"
      exact
      render={(path) => (
        <TournamentContainer
          signup={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/present"
      exact
      render={(path) => (
        <TournamentContainer
          present={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
        />
      )}
    />
    <Route
      path="/:liveLink/present/:slideshowNum"
      exact
      render={(path) => (
        <TournamentContainer
          present={true}
          user={props.user}
          userLoaded={props.userLoaded}
          liveLink={path.match.params.liveLink}
          slideshowNum={path.match.params.slideshowNum}
        />
      )}
    />
    <Route
      path="*"
      render={(path) => (
        <TournamentContainer
          liveLink={null}
          user={props.user}
          userLoaded={props.userLoaded}
        />
      )}
    />
  </Switch>
);
