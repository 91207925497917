import React from "react";
import EmptyState from "./EmptyState";
import { languages } from "../constants/languages";
import DefaultBackground from "../constants/DefaultBackground";

export const Error404 = () => {
  document.body.style.background = DefaultBackground;

  return (
    <div className="page-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto mr-auto">
            <div className="card card-signup">
              <div
                className="card-header text-center"
                style={{ background: "#2278cf", width: "auto" }}
              >
                <h4
                  className="card-title"
                  style={{ color: "#fff", marginTop: 0 }}
                >
                  {languages.tournamentNotFound}
                </h4>
              </div>
              <div style={{ padding: 20 }}>
                <EmptyState title={languages.checkUrlOr} icon="error" />
                <a href={languages.tournifyURL}>
                  <button
                    type="submit"
                    className="btn btn-tournify"
                    href={languages.tournifyURL}
                    style={{
                      marginTop: 50,
                      padding: 10,
                      width: "100%",
                      backgroundColor: "black",
                    }}
                  >
                    <i className="material-icons" style={{ marginRight: 10 }}>
                      arrow_back
                    </i>
                    {languages.startPage}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
