import { hexToRgb } from "../../util/hexToRGB";

export const addUserColorStylesheet = function (color, secondColor) {
  let rgb = hexToRgb(color);
  let colorRGB = `${rgb.r}, ${rgb.g}, ${rgb.b}`;
  var css = `
      .header-filter:before, .header-filter:after {
        z-index: -1;
      }
      .table-standings .table-points, .table-standings .table-points th {
        background-color: ${color};
        color: #fff !important;
        border-bottom: 0px !important;
      }

      .table-standings > thead > tr > th, .table-standings > tbody > tr > th, .table-standings > tfoot > tr > th, .table-standings > thead > tr > td {
        padding: 8px 0px;
      }

      .table-standings > tbody > tr > td, .table-standings > tfoot > tr > td {
        padding: 8px 0px;
        height: 40px;
      }

      .table-standings tr:nth-child(even) {
        background: rgba(${colorRGB}, 0.02);
      }

      .table-standings th {
        background: rgba(${colorRGB}, 0.10);
      }

      .table-standings tr th {
        border: 0;
      }

      .table-standings .table-custom-points {
        color: #fff;
        border-bottom: 0px;
        margin-left:4px;
      }

      .table-standings .table-score {
        color: #fff;
        background-color: ${color};
        line-height: 1.75;
      }
      .custom-points {
        color: #fff;
        line-height: 1.6;
        font-size: 0.7rem;
        margin: 8px 2px 2px 2px;
        width: 20px;
      }
      .custom1, .table-standings .custom1 {
        background-color: #7b7b7b;
      }
      .custom2, .table-standings .custom2 {
        background-color: #927878;
      }
      .custom3, .table-standings .custom3 {
        background-color: #00487d;
      }
      .custom4, .table-standings .custom4 {
        background-color: #005f6f;
      }
      .custom5, .table-standings .custom5 {
        background-color: #554200;
      }

      .card-body {
        padding: 0rem 3.5vw 3.5vw 3.5vw;
        color: #555;
      }
      .badge-tournify {
        color: #ffffff;
        background-color: ${color};
      }
      .badge-tournify[href]:hover,
      .badge-tournify[href]:focus {
        color: #ffffff;
        -webkit-box-shadow: 0 0px 5px 0px ${color},
          0 5px 5px -5px ${color};
        box-shadow: 0 0px 5px 0px ${color}, 0 5px 5px -5px ${color};
      }
      a.tournament-footer:hover,
      a.tournament-footer:focus {
        color: ${color};
      }
      .card .card-header-tournify,
      .card.bg-tournify,
      .card.card-rotate.bg-tournify .front,
      .card.card-rotate.bg-tournify .back {
        background: ${secondColor ? secondColor : color};
        border-radius: 6px 6px 0 0;
      }

      .card .card-header-tournify {
        -webkit-box-shadow: 0 5px 20px 0px ${secondColor ? secondColor : color},
          0 13px 24px -11px ${secondColor ? secondColor : color};
        box-shadow: 0 5px 20px 0px ${secondColor ? secondColor : color},
          0 13px 24px -11px ${secondColor ? secondColor : color};
      }
      .nav-pills.nav-pills-tournify .nav-item .nav-link.active,
      .nav-pills.nav-pills-tournify .nav-item .nav-link.active:focus,
      .nav-pills.nav-pills-tournify .nav-item .nav-link.active:hover {
        background-color: ${color};
        -webkit-box-shadow: 0 2px 5px 0px ${color},
          0 2px 10px -11px ${color};
        box-shadow: 0 2px 5px 0px ${color},
          0 2px 10px -11px ${color};
        color: #fff;
      }
      .dropdown-menu a:active {
        -webkit-box-shadow: 0 3px 3px 0px ${color},
          0 3px 3px -3px ${color};
        box-shadow: 0 3px 3px 0px ${color},
          0 3px 3px -3px ${color};
        background-color: ${color};
        color: #ffffff;
      }
      
      .dropdown-menu .dropdown-item:hover,
      .dropdown-menu .dropdown-item:focus,
      .dropdown-menu a.dropdown-item:hover,
      .dropdown-menu a.dropdown-item:focus,
      .dropdown-menu a:hover,
      .dropdown-menu a:focus,
      .dropdown-menu a:active {
        -webkit-box-shadow: 0 3px 3px 0px ${color},
          0 3px 3px -3px ${color};
        box-shadow: 0 3px 3px 0px ${color},
          0 3px 3px -3px ${color};
        background-color: ${color};
        color: #ffffff;
      }

      .btn.btn-tournify {
        color: #ffffff;
        border-radius: 5px;
        background-color: ${color};
        border-color: ${color};
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
      .btn-tournify:hover, .btn-tournify:focus {
        color: #ffffff;
        background-color: ${color};
        box-shadow: inset 0 0 0 99999px rgba(255,255,255,0.2);
        oppacity: 0.9;
        border-color: #ea1c0d;
      }
      .btn-tournify:focus {
        background-color: ${color};
      }
      .btn-tournify.disabled {
        color: #ffffff;
        background-color: #f44336;
        border-color: #f44336;
      }
      .btn-tournify:not(:disabled):not(.disabled):active,
      .show > .btn-tournify.dropdown-toggle {
        color: #ffffff;
        background-color: ${color};
        border-color: ${color};
      }

      .togglebutton label input[type=checkbox]:checked + .toggle {
        background-color: ${color}; }
      .togglebutton label input[type=checkbox]:checked + .toggle:after {
        border-color: ${color}; }
      .togglebutton label input[type=checkbox]:checked + .toggle:active:after {
        -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
                box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4); }

      .form-check .form-check-input:checked + .form-check-sign .check {
      background: ${color};
      border: 1px solid ${color}; 
    }

      .form-check .form-check-label .circle .check {
      background-color: ${color}; }
      .form-check .form-check-input:checked ~ .check {
      background-color: ${color}; }
      .form-check .form-check-input:checked ~ .circle {
      border-color: ${color}; }

      .card .card-header-tournify,
      .card.bg-tournify,
      .card.card-rotate.bg-tournify .front,
      .card.card-rotate.bg-tournify .back {
        background: ${secondColor ? secondColor : color};
      }

      .card .card-header-tournify {
        -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
          0 10px 24px -11px ${secondColor ? secondColor : color};
        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
          0 10px 24px -11px ${secondColor ? secondColor : color};
      }
      .card-collapse .card-header a:hover,
      .card-collapse .card-header a:active,
      .card-collapse .card-header a[aria-expanded="true"] {
        color: ${color};
      }
      .card-collapse .card-header {
        padding: 0px 10px 5px 0px;
      }

      .card-tournify {
        padding: 10px 15px 10px 15px;
        width: 100%;
        min-height: 40px;
        border: 1px solid rgba(${colorRGB}, 0.02);
        background: rgba(${colorRGB}, 0.02);
        color: #555;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        transition: transform 0.5s ease, color 0.2s ease, background 0.2s ease;
      }

      .card-tournify-signup {
        width: 100%;
        border: 1px solid rgba(${colorRGB}, 0.02);
        color: #555;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius: 5px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }

      .card-tournify-signup-body {
        padding: 30px;
      }

      .card-tournify-signup-progress {
        background-color: ${color};
        height: 5px;
        border-top-left-radius: 5px;
      }
      
      .card-tournify:hover, .card-schema:focus {
        background-color: ${color};
        color: #fff;
        box-shadow: 0 10px 20px rgba(0,0,0, 0.25);
        transform: translateY(-1px);
        cursor: pointer;
      }
      .checkin-icon {
        color: ${color};
        margin-left: 7px;
        font-size: 18px;
        padding-bottom: 3px;
      }
      .card-tournify:hover .TeamLogo, .card-tournify:hover .checkin-icon {
        color: #fff;
      }
      .progress.progress-line-tournify {
        background: rgba(${colorRGB},0.20);
      }
      .progress .progress-bar.progress-bar-tournify {
        background: ${color} !important;
      }
      .goals-table {
        width: 100%;
        color: #fff;
        background: ${color};
        border-radius: 5px;
        padding: 10px;
        table-layout: fixed;
        text-align: center;
        font-weight: bold;
        font-size: 0.875rem;
      }

      .MatchHeader {
        background: rgba(${colorRGB}, 0.10);
      }
      .BracketMatch .MatchContent {
        background: rgba(${colorRGB}, 0.02);
        border-left: 1px solid rgba(${colorRGB}, 0.10);
        border-right: 1px solid rgba(${colorRGB}, 0.10);
        border-bottom: 1px solid rgba(${colorRGB}, 0.10);
      }
      .BracketMatch .MatchContentTop {
        border-left: 1px solid rgba(${colorRGB}, 0.10);
        border-right: 1px solid rgba(${colorRGB}, 0.10);
        border-top: 1px solid rgba(${colorRGB}, 0.10);
        border-bottom: 0px solid rgba(${colorRGB}, 0.10);
      }
      .BracketMatch .MatchContentBottom {
        border-left: 1px solid rgba(${colorRGB}, 0.10);
        border-right: 1px solid rgba(${colorRGB}, 0.10);
        border-top: 0px solid rgba(${colorRGB}, 0.10);
        border-bottom: 1px solid rgba(${colorRGB}, 0.10);
      }
      .MatchContent {
        background: rgba(${colorRGB}, 0.02);
        border-left: 1px solid rgba(${colorRGB}, 0.02);
        border-right: 1px solid rgba(${colorRGB}, 0.02);
        border-bottom: 1px solid rgba(${colorRGB}, 0.02);
      }
      .MatchContentTop {
        border-left: 1px solid rgba(${colorRGB}, 0.02);
        border-right: 1px solid rgba(${colorRGB}, 0.02);
        border-top: 1px solid rgba(${colorRGB}, 0.02);
        border-bottom: 0px solid rgba(${colorRGB}, 0.02);
      }
      .MatchContentBottom {
        border-left: 1px solid rgba(${colorRGB}, 0.02);
        border-right: 1px solid rgba(${colorRGB}, 0.02);
        border-top: 0px solid rgba(${colorRGB}, 0.02);
        border-bottom: 1px solid rgba(${colorRGB}, 0.02);
      }
      .BracketLine, .BracketLineFinished {
        background: linear-gradient(rgba(${colorRGB}, 0.10), rgba(${colorRGB}, 0.10)) no-repeat center/2px 100%;
      }
      .Bracket64 .Column8 .MatchContentTop, .Bracket64 .Column8 .MatchContentBottom, .Bracket64 .Column16 .MatchContentTop, .Bracket64 .Column16 .MatchContentBottom, .Bracket32 .Column8 .MatchContentTop, .Bracket32 .Column8 .MatchContentBottom, .Column4 .MatchContentTop, .Column4 .MatchContentBottom, .Column2 .MatchContentTop, .Column2 .MatchContentBottom, .MatchContentFinal, .MatchContentFinished, .BracketMatch .MatchContentFinal, .BracketMatch .MatchContentFinished {
        border: 1px solid rgba(${colorRGB}, 0.10);
      }
      .BracketImageButton {
        height: 60px;
        background: rgba(${colorRGB}, 0.02);
        border: 1px solid rgba(${colorRGB}, 0.10);
        border-radius: 5px;
        color: #555;
        padding: 3px;
        margin: 0 5px 0 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .BracketImageButton:hover {
        background: rgba(${colorRGB}, 0.10);
        cursor: pointer;
      }
      .BracketImage32 svg, .BracketImage64 svg{
        height: 60px;
        border: 1px solid rgba(${colorRGB}, 0.10);
        border-radius: 5px;
        color: #555;
        padding: 3px;
      }
      .BracketImage64.FocusCenter svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.02) 23%, rgba(${colorRGB}, 0.10) 23% 76%, rgba(${colorRGB}, 0.02) 76%);
      }
      .BracketImage64.FocusLeft64 svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.10) 50%, rgba(${colorRGB}, 0.02) 50%);
      }
      .BracketImage64.FocusLeft32 svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.02) 17%, rgba(${colorRGB}, 0.10) 17% 50%, rgba(${colorRGB}, 0.02) 50%);
      }
      .BracketImage64.FocusRight64 svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.02) 50%, rgba(${colorRGB}, 0.10) 50%);
      }
      .BracketImage64.FocusRight32 svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.02) 50%, rgba(${colorRGB}, 0.10) 50% 84%, rgba(${colorRGB}, 0.02) 84%);
      }
      .BracketImage32.FocusCenter svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.02) 17%, rgba(${colorRGB}, 0.10) 17% 83%, rgba(${colorRGB}, 0.02) 83%);
      }
      .BracketImage32.FocusLeft32 svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.10) 50%, rgba(${colorRGB}, 0.02) 50%);
      }
      .BracketImage32.FocusRight32 svg {
        background: linear-gradient(to right, rgba(${colorRGB}, 0.02) 50%, rgba(${colorRGB}, 0.10) 50%);
      }

      .SlideTitle, .MatchListTitle, .GroupHeaderName, .GroupHeaderColumn, .GroupColumnPoints, .DressingRoomHeaderTeam, .TeamLayoutHeaderTeam, .RankingHeaderTeam, .Present-BracketMatchTeamScore  {
        background: ${color};
      }

      .MatchColumnScore {
        background: ${color};
        box-shadow: inset 6px 0 0 0 ${color}, inset -6px 0 0 0 ${color};
      }

      .MatchColumnTeam1, .MatchColumnTeam2, .GroupColumnTeam, .DressingRoomColumnTeam, .TeamLayoutColumnPlayer, .RankingColumnTeam, .Present-BracketMatchTeamName {
        color: rgba(${colorRGB}, 0.4);
      }

      .TeamLogo {
        color: rgba(${colorRGB}, 0.20);
      }
      .Referee {
        background: rgba(${colorRGB}, 0.10);
      }

      .SubmitScoreButton {
        background: ${color};
      }

      .SubmitScoreButton:hover {
        background: rgba(${colorRGB}, 0.20);
        color: #555;
      }

      .EmptyState {
        text-align: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
      .EmptyState svg {
        color: rgba(${colorRGB},100);
      }
      .EmptyTitle {
        font-weight: 700;
      }

      .checkin-card {
        background: rgba(${colorRGB}, 0.10);
      }

      .closeIcon {
        background: ${color};
      }

      .TeamDetailsCard {
        background: rgba(${colorRGB}, 0.02);
        border-left: 1px solid rgba(${colorRGB}, 0.02);
        border-top: 1px solid rgba(${colorRGB}, 0.02);
        border-right: 1px solid rgba(${colorRGB}, 0.02);
      }

      .RefereeDetailsCard {
        background: rgba(${colorRGB}, 0.02);
        border: 1px solid rgba(${colorRGB}, 0.02);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }

      .TeamDetailsTab {
        background: rgba(${colorRGB}, 0.10);
        color: #555;
      }

      .TeamDetailsTab:hover {
        background: rgba(${colorRGB}, 0.20);
      }
      
      .TeamDetailsTabActive {
        background: ${color};
        color: #fff;
      }

      // .TeamDetailsTabs .WithoutHeader .TeamDetailsTab {
      //   border-right: 2px solid ${color};
      // }
      
      // .TeamDetailsTabs .WithoutHeader .TeamDetailsTab:last-child {
      //   border-right: none;
      // }

      .BacktoSearchButton, .registerAnotherTeamButton {
        background: rgba(${colorRGB}, 0.10);
        color: #555;
      }

      .BacktoSearchButton:hover, .registerAnotherTeamButton:hover {
        background: rgba(${colorRGB}, 0.20);
      }

      .playerContainer {
        border: 1px solid rgba(${colorRGB}, 0.10);
      }

      .playerItem {
        background: rgba(${colorRGB}, 0.10);
      }

      .OpenInAppCard {
        background: ${color};
      }

      .tournifyBGLight {
        background: rgba(${colorRGB}, 0.05);
      }

      .closeOpenInAppCard {
        position: absolute;
        right: 10px;
        margin-top: -20px;
        color: rgba(${colorRGB}, 0.20);
        background-color: white;
        font-size: 20px;
      }

      .OpenInAppButton {
        background: rgba(${colorRGB}, 0.20);
      }

      .OpenInAppButton:after {
        border: 7px solid ${color};
        border-color: transparent transparent ${color} transparent;
      }

      .collapse-schedule .card-header {
        background: rgba(${colorRGB}, 0.10);
        padding: 8px 10px;
      }

      .card-collapse .card-header.header-ranking {
        border-bottom: 1px solid rgba(${colorRGB}, 0.10);
      }

      .collapse-schedule .card-header, .collapse-schedule .card-header a:hover, .collapse-schedule .card-header a:active, .collapse-schedule .card-header a[aria-expanded="true"] {
        color: #555;
      }

      .MatchDate {
        background: rgba(${colorRGB}, 0.10);
      }

      .SlideDurationBar {
        background: ${color};
      }

      .InfoCardHeader {
        background: rgba(${colorRGB}, 0.10);
      }

      .InfoCardDescription, .TournamentDescription {
        background: rgba(${colorRGB}, 0.02);
        border-left: 1px solid rgba(${colorRGB}, 0.02);
        border-bottom: 1px solid rgba(${colorRGB}, 0.02);
        border-right: 1px solid rgba(${colorRGB}, 0.02);
      }

      .TournamentDescription {
        border-top: 1px solid rgba(${colorRGB}, 0.02);
        border-radius: 5px;
        padding: 15px;
      }

      .TournamentDescription a {
        color: ${color};
        font-weight: 500;
      }

      .TournamentDescription a:hover {
        color: ${color};
        text-decoration: underline;
      }

      .InfoCardDescription .ProgressBar {
        background: ${color};
      }

      .BracketHeader {
        background: rgba(${colorRGB}, 0.10);
      }

      .EmptyMatchList {
        background: repeating-linear-gradient(
          rgba(21, 120, 222, 0.02),
          rgba(21, 120, 222, 0.02) 1.8em,
          rgba(21, 120, 222, 0.10) 1.8em,
          rgba(21, 120, 222, 0.10) 3.6em
        );
        height: 100%;
        display: flex;
        align-items: center;
      }

      .TournifySearch {
        background: rgba(${colorRGB}, 0.02);
        border: 1px solid rgba(${colorRGB}, 0.02);
      }

      .form-control:focus {
        background-image: linear-gradient(to top, ${color} 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
      }

      .btn.btn-ranking, .btn-ranking:focus {
        display: flex;
        color: #555;
        border-radius: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 30px;
        font-weight: 500;
        font-size: 12px;
        background-color: rgba(${colorRGB}, 0.10);
        border-color: rgba(${colorRGB}, 0.10);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
      .btn-ranking:hover {
        color: #555;
        background-color: rgba(${colorRGB}, 0.20);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
      .btn-ranking:not(:disabled):not(.disabled):active,
      .show > .btn-ranking.dropdown-toggle {
        color: #555;
        background-color: rgba(${colorRGB}, 0.20);
        border-color: rgba(${colorRGB}, 0.20);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }

      .btn.btn-ranking-active {
        display: flex;
        color: #fff;
        background: ${color};
        border-radius: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 30px;
        font-weight: 500;
        font-size: 12px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }

      .infoIconGroup {
        color: rgba(${colorRGB}, 0.3)
      }

      .QRCodeIcon {
        background: ${color};
      }

      @media only screen and (max-width: 767px) {
        .table-score {
          color: #fff;
          background-color: ${color};
          width: 15vw;
        }
        .table-time {
          width: 15vw;
        }
        .card-body {
          padding: 0rem 1.25rem 1.25rem 1.25rem;
        }
      }
      @media only screen and (min-width: 768px) {
        .MatchHover:hover > .BoxShadow > .MatchHeader,
        .MatchHover:hover > .BoxShadow > .MatchContent,
        .MatchHover:hover > .MatchFooter > .Referees > .Referee {
          background: ${color};
          color: #fff;
        }
      }
      @media screen and (max-width: 460px) {
        .OpenInAppCard {
          border: 1px solid rgba(${colorRGB}, 0.02);
          background: rgba(${colorRGB}, 0.02);
        }
        .OpenInAppButton:after {
          border: 7px solid rgba(${colorRGB}, 0.08);
          border-color: transparent transparent rgba(${colorRGB}, 0.08) transparent;
        }
        .OpenInAppCard .btn.btn-tournify {
          background-color: ${color};
          color: white;
        }
        
        .OpenInAppCard .btn.btn-tournify:hover,
        .OpenInAppCard .btn-tournify:not(:disabled):not(.disabled):active {
          background-color: rgba(${colorRGB}, 0.9);
          color: white;
        }
      }
      .PanelLeft, .PanelRight {
        background: rgba(${colorRGB}, 0.10);
      }
    `;

  var style = document.createElement("style");
  style.appendChild(document.createTextNode(css));
  document.getElementsByTagName("head")[0].appendChild(style);
};
